import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/product.service";
import categoryHttpService from "../../../services/http/products/category.service";
import brandHttpService from "../../../services/http/products/brand.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useSelector } from "react-redux";
const ProductList = () => {
  const { hasPermission, isVendorOrOutlet } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [eanFilter, setEanFilter] = useState("");
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getProductList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .productList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      ean: eanFilter,
      category_id: selectedCategoryId?.value,
      brand_id: selectedBrandId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getProductList(pagination, nonNullFilters, sorter);
  };

  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    fetchBrand();
    fetchCategory();
    if (isVendorOrOutlet) {
      getProductList();
    } else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      setSelectedVendor({
        ...selectedVendor,
        value: selectedVendor?.id,
        label: selectedVendor?.business_name,
      });
    }
  }, []);
  async function fetchCategory() {
    try {
      const Category = await categoryHttpService.categoriesList();
      setCategory(Category?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const onClickExportAs = async (type) => {
    setExportLoading(true);
    try {
      const response = await httpService.exportProducts({ export_type: type });
      window.open(response.data.data, "_blank");
    } catch (error) {
      console.log("Error ", error);
    } finally {
      setExportLoading(false);
    }
  };

  async function fetchBrand() {
    try {
      const brand = await brandHttpService.brandsList();
      setBrand(brand?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteProduct(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("product.productDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getProductList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewProduct = () => {
    navigate(all_routes.productCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.catalog?.name}
        </div>
      ),
    },

    // {
    //   title: t("tableColumnName.category"),
    //   dataIndex: "category",
    //   render: (text, record) => record?.catalog?.category?.name,
    // },
    {
      title: t("tableColumnName.sku"),
      dataIndex: "sku",
      sorter: true,
    },
    // {
    //   title: t("tableColumnName.uomCode1"),
    //   dataIndex: "catalog.uom_code_1.name",
    //   render: (text, record) => record?.catalog.uom_code_1?.name,
    // },
    {
      title: t("tableColumnName.brand"),
      dataIndex: "catalog.brand.name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.brand?.image}
            alt="Brand"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("tableColumnName.minOrderQty"),
      dataIndex: "min_order_qty",
      sorter: true,
      align: "center",
    },
    // {
    //   title: t("tableColumnName.createdBy"),
    //   dataIndex: "created_by",
    //   render: (text, record) => {
    //     return record?.created_by?.name ?? "";
    //   },
    // },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   render: (text, record) => (
    //     <div className="d-flex align-items-center">
    //       <ImageWithBasePath
    //         type="link"
    //         src={record?.vendor?.logo}
    //         alt="Vendor"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission([
        "product-view",
        "product-edit",
        "product-delete",
      ]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["product-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/product/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["product-edit"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/product/${record.id}/edit`)}
              >
                <i className="feather-edit-2 text-black"></i>
              </a>
            )}
            {hasPermission(["product-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("product.productList"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  const categoryOptions = [
    { value: null, label: t("inputField.placeholder.chooseCategory") },
    ...(category.map((category) => ({
      value: category?.id,
      label: category?.name,
    })) || []),
  ];

  const brandOptions = [
    { value: null, label: t("inputField.placeholder.chooseBrand") },
    ...(brand.map((brand) => ({
      value: brand?.id,
      label: brand?.name,
    })) || []),
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("product.productList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>

            {/* <div className="page-btn">
              <button
                onClick={() => navigate(all_routes.ProductFromCatalog)}
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.uploadDigitalDisplays")}
              </button>
            </div> */}
            <div className="page-btn">
              <button
                className="btn btn-added dropdown-toggle nav-link userset"
                data-bs-toggle="dropdown"
              >
                <>
                  {exportLoading ? (
                    <UnlimitLoading width="23px" height="23px" color="white" />
                  ) : (
                    <Plus className="me-2 iconsize" />
                  )}
                  <p className="mb-0 me-1 ms-1">
                    {t("general.button.exportAs")}
                  </p>
                  <div className="nav-item dropdown has-arrow main-drop">
                    <div className="dropdown-menu menu-drop-user">
                      <div className="profilename">
                        <p
                          className="me-2 dropdown-item w-100"
                          onClick={() => onClickExportAs("pdf")}
                        >
                          {t("general.pdf")}
                        </p>
                        <p
                          onClick={() => onClickExportAs("excel")}
                          className="me-2 dropdown-item w-100"
                          data-feather="settings"
                        >
                          {t("general.excel")}
                        </p>
                        <hr className="m-0" />
                      </div>
                    </div>
                  </div>
                </>
              </button>
            </div>
            {hasPermission(["product-add-from-catalog"]) && (
              <div className="page-btn">
                <button
                  onClick={() => navigate(all_routes.ProductFromCatalog)}
                  className="btn btn-added"
                >
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addFromCatalog")}
                </button>
              </div>
            )}
            {hasPermission(["product-add"]) && (
              <div className="page-btn">
                <button onClick={addNewProduct} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet && <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.label.EAN")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={eanFilter}
                    onChange={(e) => setEanFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("tableColumnName.productName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <div className="input-blocks mb-0">
                    <Select
                      options={brandOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseBrand")}
                      value={selectedBrandId}
                      onChange={(selectedOption) =>
                        setSelectedBrandId(selectedOption)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-sm-4 col-12">
                  <div className="input-blocks mb-0">
                    <Select
                      options={categoryOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseCategory")}
                      value={selectedCategoryId}
                      onChange={(selectedOption) =>
                        setSelectedCategoryId(selectedOption)
                      }
                    />
                  </div>
                </div>
                
                <div className="col-lg-1 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
