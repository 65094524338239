import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import Select from "react-select";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/sales/sales.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
const SalesList = () => {
  const { hasPermission, roleType } = useRoleAndPerm();
  const [nameFilter, setNameFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const getPurchaseList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .salesList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      customer_name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getPurchaseList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("Sales.SalesID"),
      dataIndex: "order_code",
      render: (text, record) => {
        return record?.order_code ?? "";
      },
    },
    {
      title: t("customer.loyaltyID"),
      dataIndex: "customer_code",
      render: (text, record) => {
        return record?.customer?.loyalty_id ?? "";
      },
    },
    {
      title: t("Sales.customerName"),
      dataIndex: "customer",
      render: (text, record) => {
        return record?.customer?.name ?? "";
      },
    },

    {
      title: "Total Amount",
      dataIndex: "total_amount",
      align: "right",
      render: (text, record) => {
        return record?.total_amount ?? "N/A";
      },
    },
    {
      title: t("Sales.paymentStatus"),
      dataIndex: "payment_status",
      align: "center",
      render: (text, record) => {
        return record?.payment_status == "PAID" ? (
          <span className="badge badge-linesuccess">
            <a>{t("general.actions.paid")}</a>
          </span>
        ) : (
          <span className="badge badge-linedanger">
            <a>{t("general.actions.pending")}</a>
          </span>
        );
      },
    },
    {
      title: t("tableColumnName.earnedPoints"),
      dataIndex: "earn_points",
      align: "center",
    },
    {
      title: t("tableColumnName.usedPoints"),
      dataIndex: "redeem_points",
      align: "center",
    },
    {
      title: t("logs.date"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
    {
      title: t("outlet.outlet"),
      dataIndex: "outlet",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.outlet?.image_url}
            alt="Contact"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.outlet?.name}
        </div>
      ),
    },
    // {
    //   title: t("vendor.vendor"),
    //   dataIndex: "vendor",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <div className="d-flex align-items-center">
    //       <ImageWithBasePath
    //         type="link"
    //         src={record.vendor?.logo_url}
    //         alt="Contact"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //       {record?.vendor?.business_name}
    //     </div>
    //   ),
    // },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["sale-view"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["sale-view"]) && (
              <Link
                to={`/sales/${record.id}/details`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("Sales.Sales"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("Sales.SalesList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top row">
                <div className="col-lg-5 col-sm-5 col-12">
                  <input
                    type="text"
                    placeholder={t("Sales.enterCustomerName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default SalesList;
