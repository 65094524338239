import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import httpService from "../../../services/http/thirdparty/third-party.service";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment/moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import ThirdPartyDetail from "../../../components/third-party-detail";
const ThirdPartyList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [thirdParty, setThirdParty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [thirdParties, setThirdParties] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const getThirdPartyList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .thirdPartyList(data)
      .then((response) => {
        if (response.status == 200) {
          setThirdParties(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getThirdPartyList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getThirdPartyList();
  }, []);


  const onConfirmDelete = async (id) => {
    await httpService
      .deleteThirdParty(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("thirdParty.thirdPartyDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getThirdPartyList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onHideModal = async () => {
    console.log("hide modal");
    setThirdParty(null);
    await getThirdPartyList();
  };
  const editThirdParty = (row) => {
    setThirdParty(row);
    const addButton = document.querySelector(".btn-added");
    if (addButton) {
      addButton.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.userName"),
      dataIndex: "user_name",
      // sorter: true,
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
      // sorter: true,
    },
    // {
    //   title: t("tableColumnName.createdBy"),
    //   dataIndex: "created_by",
    //   render: (text, record) => {
    //     return record.created_by?.name ?? "";
    //   },
    // },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    // {
    //   title: t("tableColumnName.status"),
    //   dataIndex: "is_active",
    //   render: (text, record) => {
    //     if (record.is_active == 1) {
    //       return (
    //         <span className="badge badge-linesuccess">
    //           <a>{t("general.actions.activate")}</a>
    //         </span>
    //       );
    //     } else {
    //       return (
    //         <span className="badge badge-linedanger">
    //           <a>{t("general.actions.deactive")}</a>
    //         </span>
    //       );
    //     }
    //   },
    // },

    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["thirdParty-edit", "thirdParty-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["thirdParty-edit"]) && (
              <a className="me-2 p-2" onClick={() => editThirdParty(record)}>
                <i className="feather-edit-2 "></i>
              </a>
            )}
            {hasPermission(["thirdParty-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  // Breadcrumb items
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("thirdParty.thirdPartyList"), active: true },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("thirdParty.thirdPartyList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div
              className="page-btn"
              style={
                {
                  // display: hasPermission(["thirdParty-add"]) ? "block" : "none",
                }
              }
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-department"
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top row">
                <div className="col-lg-5 col-sm-5 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={thirdParties}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ThirdPartyDetail onHide={onHideModal} thirdParty={thirdParty} />
    </div>
  );
};

export default ThirdPartyList;
