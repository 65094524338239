import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/settings/system-language.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import notificationService from "../../../services/notification.service";
import { store } from "../../../core/redux/store";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ReactJson from "react-json-view";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useForm } from "react-hook-form";

export default function UpdateLanguageJson() {
  const [pageLoading, setPageLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [systemLanguageJsons, setSystemLanguageJsons] = useState(null);
  const [editedJson, setEditedJson] = useState({});
  const [isJsonValid, setIsJsonValid] = useState(false);
  const [platform, setPlatform] = useState();
  const [vendor, setVendor] = useState();
  const { id,type,vendorId } = useParams();
  const { auth } = store.getState();
  const roles = auth.roles ?? []; 
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    reset,
    control,
  } = useForm();
  useEffect(() => {
    if (id) {
      setPlatform(type === "1" ? "APP" : "CRM")
      setVendor(vendorId != 0? vendorId : null)
      getJsonData();
    }
  }, [id]);

  const getJsonData = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getSystemLanguageJson(id,type === "1" ? "APP" : "CRM",vendorId != 0? vendorId : null).catch((err)=>{console.log(err)})
      if (response && response.status === 200) {
        setSystemLanguageJsons(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
        setValue("is_active",response.data.data.is_active);
        setEditedJson(JSON.parse(response.data.data.translation));
        setIsJsonValid(true);
      }
    } finally {
      setPageLoading(false);
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("language.language"), link: "/system-language-list" },
    { label: t("language.systemLanguageJsonUpdate"), active: true },
  ];

  const handleJsonChange = (edit) => {
    setEditedJson(edit.updated_src);
  };

  const validateJson = (json) => {
    try {
      JSON.parse(JSON.stringify(json));
      setIsJsonValid(true);
      notificationService.successMessage(t('general.prompts.jsonValid'));
    } catch (error) {
      setIsJsonValid(false);
      notificationService.errorMessage(t('general.prompts.jsonInvalid'));
    }
  };

  const updateSystemLanguageJson = async () => {
    if (!isJsonValid) {
      notificationService.errorMessage(t('general.prompts.validateJson'));
      return;
    }
    const requestData = {
      is_active: isActive ? 1 : 0,
      platform: platform,
      language_id: id,
      translation: JSON.stringify(editedJson)
    };
    
    if (id) {
      requestData.id = id;
      requestData.vendor_id=vendor;
    }
    await httpService
      .updateSystemLanguageJson(requestData,true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(
            t("language.systemLanguageJsonUpdateSuccess")
          );
        }
        navigate(`/system-language-list`)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">
                  {t("language.systemLanguageJsonUpdate")}
                </h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="p-3 card">
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.platform")}
                            details={platform}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.createdAt")}
                            details={moment(
                              systemLanguageJsons?.created_at
                            ).format("DD MMM YYYY - HH:mm:ss")}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.status")}
                            details={<UnlimitToggle
                            id="is_active"
                            register={register}
                          />}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <ReactJson
                          src={editedJson}
                          onEdit={handleJsonChange}
                          onAdd={handleJsonChange}
                          onDelete={handleJsonChange}
                          theme="monokai"
                          style={{ fontSize: "16px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <button
                    className="btn btn-secondary me-3"
                    onClick={() => validateJson(editedJson)}
                  >
                    {t('general.button.validateJson')}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={updateSystemLanguageJson}
                    disabled={!isJsonValid}
                  >
                    {t("general.button.update")}
                  </button>
                </div>
              </>
          )}
        </div>
      </div>
    </>
  );
}
