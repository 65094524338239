import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const ExpiredProductList = ({ t, ProductsList }) => {
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: t("general.prompts.confirmationTitle"),
      text: t("general.prompts.confirmRevert"),
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: t("general.actions.yesDelete"),
      cancelButtonColor: "#ff0000",
      cancelButtonText: t("general.button.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: t("general.prompts.deleted"),
          text: t("product.productDeleteSuccess"),
          className: "btn btn-success",
          confirmButtonText: t("general.button.ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };
  return (
    <div className="col-7">
    <div className="card">
    <div className="card-header">
      <h4 className="card-title">{t("dashboard.expiryProducts")}</h4>
    </div>
    <div className="card-body">
      <div className="table-responsive dataview">
        <table className="table dashboard-expired-products">
          <thead>
            <tr>
              <th className="no-sort">{t("tableColumnName.srNo")}</th>
              <th>{t("product.product")}</th>
              <th>{t("tableColumnName.sku")}</th>
              <th>{t("dashboard.expiryDate")}</th>
              <th className="no-sort">
                {t("tableColumnName.actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <div className="productimgname">
                  <Link to="#" className="product-img stock-img">
                    <ImageWithBasePath
                      src="assets/img/products/expire-product-01.png"
                      alt="product"
                    />
                  </Link>
                  <Link to="#">Red Premium Handy </Link>
                </div>
              </td>
              <td>
                <Link to="#">PT006</Link>
              </td>
              <td>29 Mar 2023</td>
              <td>
                <div className="edit-delete-action">
                  <Link className="me-2 p-2" to="#">
                    <i data-feather="edit" className="feather-edit" />
                  </Link>
                  <Link
                    className=" confirm-text p-2"
                    to="#"
                    onClick={showConfirmationAlert}
                  >
                    <i
                      data-feather="trash-2"
                      className="feather-trash-2"
                    />
                  </Link>
                </div>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <div className="productimgname">
                  <Link to="#" className="product-img stock-img">
                    <ImageWithBasePath
                      src="assets/img/products/expire-product-02.png"
                      alt="product"
                    />
                  </Link>
                  <Link to="#">Iphone 14 Pro</Link>
                </div>
              </td>
              <td>
                <Link to="#">PT007</Link>
              </td>
              <td>04 Apr 2023</td>
              <td >
                <div className="edit-delete-action">
                  <Link className="me-2 p-2" to="#">
                    <i data-feather="edit" className="feather-edit" />
                  </Link>
                  <Link
                    className="confirm-text p-2"
                    to="#"
                    onClick={showConfirmationAlert}
                  >
                    <i
                      data-feather="trash-2"
                      className="feather-trash-2"
                    />
                  </Link>
                </div>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <div className="productimgname">
                  <Link to="#" className="product-img stock-img">
                    <ImageWithBasePath
                      src="assets/img/products/expire-product-03.png"
                      alt="product"
                    />
                  </Link>
                  <Link to="#">Black Slim 200 </Link>
                </div>
              </td>
              <td>
                <Link to="#">PT008</Link>
              </td>
              <td>13 May 2023</td>
              <td>
                <div className="edit-delete-action">
                  <Link className="me-2 p-2" to="#">
                    <i data-feather="edit" className="feather-edit" />
                  </Link>
                  <Link
                    className=" confirm-text p-2"
                    to="#"
                    onClick={showConfirmationAlert}
                  >
                    <i
                      data-feather="trash-2"
                      className="feather-trash-2"
                    />
                  </Link>
                </div>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <div className="productimgname">
                  <Link to="#" className="product-img stock-img">
                    <ImageWithBasePath
                      src="assets/img/products/expire-product-04.png"
                      alt="product"
                    />
                  </Link>
                  <Link to="#">Woodcraft Sandal</Link>
                </div>
              </td>
              <td>
                <Link to="#">PT009</Link>
              </td>
              <td>27 May 2023</td>
              <td>
                <div className="edit-delete-action">
                  <Link className="me-2 p-2" to="#">
                    <i data-feather="edit" className="feather-edit" />
                  </Link>
                  <Link
                    className=" confirm-text p-2"
                    to="#"
                    onClick={showConfirmationAlert}
                  >
                    <i
                      data-feather="trash-2"
                      className="feather-trash-2"
                    />
                  </Link>
                </div>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <div className="productimgname">
                  <Link to="#" className="product-img stock-img">
                    <ImageWithBasePath
                      src="assets/img/products/stock-img-03.png"
                      alt="product"
                    />
                  </Link>
                  <Link to="#">Apple Series 5 Watch </Link>
                </div>
              </td>
              <td>
                <Link to="#">PT010</Link>
              </td>
              <td>26 May 2023</td>
              <td>
                <div className="edit-delete-action">
                  <Link
                    className="me-2 p-2"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-units"
                  >
                    <i data-feather="edit" className="feather-edit" />
                  </Link>
                  <Link
                    className=" confirm-text p-2"
                    to="#"
                    onClick={showConfirmationAlert}
                  >
                    <i
                      data-feather="trash-2"
                      className="feather-trash-2"
                    />
                  </Link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
  );
};

export default ExpiredProductList;
