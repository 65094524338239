import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/peoples/employees.service";
import usePagination from "../../../custom-hooks/usePagination";
import outletHttpService from "../../../services/http/stores/outlet.service";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
const EmployeesList = () => {
  const { hasPermission, roleType,isVendorOrOutlet } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const navigate = useNavigate();
  const getEmployeesList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .employeesList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
      outlet_id: selectedOutletId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getEmployeesList(pagination, nonNullFilters, sorter);
  };

  async function fetchOutlet() {
    try {
      const outlet = await outletHttpService.outletList();
      setOutlets(outlet?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }

  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    fetchOutlet();
    if(isVendorOrOutlet){
      getEmployeesList()
    }else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];
      
      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }
    
    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }
  }, []);

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteEmployees(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("employee.employeeDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getEmployeesList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewEmployee = () => {
    navigate(all_routes.employeesCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.employeeId"),
      dataIndex: "employee_code",
    },
    {
      title: t("tableColumnName.name"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.image}
            alt={t("tableColumnName.image")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.name}
        </div>
      ),
      sorter: true,
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
    },
    {
      title: t("tableColumnName.designation"),
      dataIndex: "designation",
      render: (text, record) =>
        record.designation_id ? record.designation_id.name : null,
    },
    {
      title: t("tableColumnName.outlets"),
      dataIndex: "outlet.name",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <Link
          to={`/outlets/${record.outlet.id}/details`}
          className="me-2 edit-icon p-2 d-flex align-items-center me-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.outlet.image}
              alt={t("tableColumnName.vendor")}
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record.outlet.name}
          </div>
        </Link>
      ),
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor.id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <div className="d-flex align-items-center">
    //         <ImageWithBasePath
    //           type="link"
    //           src={record.vendor.logo}
    //           alt={t("tableColumnName.vendor")}
    //           style={{ width: 30, height: 30, marginRight: 10 }}
    //         />
    //         {record.vendor.business_name}
    //       </div>
    //     </Link>
    //   ),
    // },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission([
        "employee-view",
        "employee-edit",
        "employee-delete",
      ]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["employee-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/employees/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["employee-edit"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/employees/${record.id}/edit`)}
              >
                <i className="feather-edit-2 text-black"></i>
              </a>
            )}
            {hasPermission(["employee-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("employee.employees"), active: true },
  ];

  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  const outletOptions = [
    { value: null, label: t("inputField.label.selectOutlet") },
    ...(outlets?.map((outlet) => ({
      value: outlet.id,
      label: outlet.name,
    })) || []),
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("employee.employees")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["employee-add"]) && (
              <div className="page-btn">
                <button onClick={addNewEmployee} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet &&<div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder={t("inputField.placeholder.enterEmployeeId")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      value={idFilter}
                      onChange={(e) => setIdFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterEmployeeName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                {roleType !== 'outlet' && <div className="col-lg-3 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <Select
                      options={outletOptions}
                      className="select custom-select"
                      placeholder={t("inputField.label.selectOutlet")}
                      value={selectedOutletId}
                      onChange={(selectedOption) =>
                        setSelectedOutletId(selectedOption)
                      }
                    />
                  </div>
                </div>}
                <div className="col-lg-3 col-sm-4 col-12  ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default EmployeesList;
