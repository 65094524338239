import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT || 80,
    wssPort: process.env.REACT_APP_REVERB_PORT || 443,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME || 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    wsPath: '/wss',
    authEndpoint: 'https://dev.api.admin.unlimitretail.dk/broadcasting/auth',
    auth: {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    },
});

export function subscribeToUserPrivateChannel(ticketId, handleSocketMessage) {
    console.log("Subscribing to channel...");

    const channel = echo.private(`ticket.${ticketId}`)
        .listen('.ticket.message_received', (data) => {
            console.log("Message Received ", data);
            handleSocketMessage(data);
        })
        .error(function (error) {
            console.error("Error in Echo ", JSON.stringify(error));
        });

    const state = channel.pusher.connection.state;
    console.log("State: ", state);

    if (state === 'connected') {
        const socket_id = channel.pusher.connection.socket_id;
        console.log("Socket ID# ", socket_id);
    } else {
        channel.pusher.connection.bind('connected', function (data) {
            const socket_id = data.socket_id;
            console.log("Socket ID ", socket_id);
            console.log("State#: ", channel.pusher.connection.state);
        });
    }

    return channel;
}

export function unsubscribeFromUserPrivateChannel(ticketId) {
        const channelName = `ticket.${ticketId}`;
        echo.leave(channelName);
        console.log(`Unsubscribed from channel: ${channelName}`);
}
export function subscribeToNotificationsChannel(userId, handleNotificationMessage) {
    console.log("Subscribing to notifications channel...");
    const channel = echo.private(`user.${userId}.notifications`)
    .listen('.send.notification', (data) => {
        console.log("Notification Received ", data);
        handleNotificationMessage(data);
    })
    .error(function (error) {
        console.error("Error in Echo (Notifications)", JSON.stringify(error));
    });
    if (channel.pusher.connection.state === 'connected') {
        console.log("connected")
    }

    return channel;
}


export function unsubscribeFromNotificationsChannel(userId) {
    const channelName = `user.${userId}.notifications`;
    echo.leave(channelName);
    console.log(`Unsubscribed from notifications channel: ${channelName}`);
}

export default echo;
