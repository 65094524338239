import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import Select from "react-select";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/peoples/supplier.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import moment from "moment";
import { useSelector } from "react-redux";

const SupplierList = () => {
  const { hasPermission, roleType,isVendorOrOutlet } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [designations, setDesignations] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const [idFilter, setIdFilter] = useState("");
  const {
    paginationParams,
    updatePaginationParams,
    sorterParams,
    updateSorterParams,
  } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getSuppliersList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .supplierList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      email: emailFilter,
      name: nameFilter,
      id: idFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getSuppliersList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    if(isVendorOrOutlet){
      getSuppliersList()
    }else{
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];
      
      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }
    
    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }
  }, []);

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteSupplier(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("supplier.supplierDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getSuppliersList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewSupplier = () => {
    navigate(all_routes.supplierCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      render: (value, item, index) => index + 1,
    },
    {
      title: t("tableColumnName.supplierId"),
      dataIndex: "supplier_code",
      sorter: true,
    },
    {
      title: t("tableColumnName.supplierName"),
      dataIndex: "name",
      sorter: true,
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.image}
            alt="Supplier"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("inputField.label.outletName"),
      dataIndex: "name",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record?.outlet?.name ? (
            <>
              <Link
                to={`/outlets/${record.outlet?.id}/details`}
                className="me-2 edit-icon p-2 d-flex align-items-center me-2"
              >
                <ImageWithBasePath
                  type="link"
                  src={record?.outlet?.image_url}
                  alt="Outlet"
                  style={{ width: 30, height: 30, marginRight: 10 }}
                />
                <p className="">{record.outlet?.name} </p>
              </Link>
            </>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor?.id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <ImageWithBasePath
    //         type="link"
    //         src={record.vendor?.logo_url}
    //         alt="Contact"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //       {record?.vendor?.business_name}
    //     </Link>
    //   ),
    // },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
      sorter: true,
    },
    // {
    //   title: t("tableColumnName.country"),
    //   dataIndex: "country_id",
    //   render: (text, record) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         {record.country?.iso2 && (
    //           <UnlimitCountryIcon iso2={record.country?.iso2} />
    //         )}
    //         <div className="ms-2">{record.country?.name}</div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: t("tableColumnName.createdBy"),
    //   dataIndex: "created_by",
    //   render: (text, record) => {
    //     return record.created_by?.name ?? "";
    //   },
    // },
    // {
    //   title: t("tableColumnName.createdAt"),
    //   dataIndex: "created_at",
    //   render: (text) => {
    //     return moment(text).format("DD MMM YYYY - HH:mm:ss");
    //   },
    // },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.active")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission([
        "supplier-view",
        "supplier-edit",
        "supplier-delete",
      ]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["supplier-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/supplier/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["supplier-edit"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/supplier/${record.id}/edit`)}
              >
                <i className="feather-edit-2 text-black"></i>
              </a>
            )}
            {hasPermission(["supplier-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("supplier.suppliers"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("supplier.supplierList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["supplier-add"]) && (
              <div className="page-btn">
                <button onClick={addNewSupplier} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet && <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12 mb-2">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder={t("inputField.placeholder.enterSupplierID")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      value={idFilter}
                      onChange={(e) => setIdFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-12 mb-2">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterSupplierName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 mb-2">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterEmail")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto"
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default SupplierList;
