import { useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/peoples/customers.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import notificationService from "../../../services/notification.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import { store } from "../../../core/redux/store";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
export default function CustomersDetails() {
  const { hasPermission, roleType } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const [customer, setCustomer] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getCustomer = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getCustomer(id);
      if (response && response.status === 200) {
        setCustomer(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCustomer();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("customer.customers"), link: "/customers-list" },
    { label: t("customer.customerDetails"), active: true },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("Sales.SalesID"),
      dataIndex: "order_code",
      render: (text, record) => {
        return record?.order_code ?? "";
      },
    },
    {
      title: t("Sales.totalAmount"),
      dataIndex: "total_amount",
      align: "right",
      render: (text, record) => {
        return record?.total_amount ?? "N/A";
      },
    },
    {
      title: t("tableColumnName.earnedPoints"),
      dataIndex: "created_at",
      align: "center",
      render: (text, record) => {
        return record?.earn_point_transaction?.total_points;
      },
    },
    {
      title: t("tableColumnName.redeemPoints"),
      dataIndex: "created_at",
      align: "center",
      render: (text, record) => {
        return record?.redeem_point_transaction?.total_points || "N/A";
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (text, record) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
    {
      title: t("Sales.paymentStatus"),
      dataIndex: "payment_status",
      align: "center",
      render: (text, record) => {
        return record?.payment_status == "PAID" ? (
          <span className="badge badge-linesuccess">
            <a>{t("general.actions.paid")}</a>
          </span>
        ) : (
          <span className="badge badge-linedanger">
            <a>{t("general.actions.pending")}</a>
          </span>
        );
      },
    },
    {
      title: t("outlet.outlet"),
      dataIndex: "outlet",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.outlet?.image_url}
            alt="Contact"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.outlet?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["customer-view"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["customer-view"]) && (
              <Link
                to={`/sales/${record.id}/details`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
          </div>
        </td>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("customer.customer")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                customer && (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="border p-3 mb-3">
                          <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              <ImageWithBasePath
                                src={customer?.vendor?.logo_url}
                                height={60}
                                width={60}
                                type="link"
                              ></ImageWithBasePath>
                              <span
                                className={`badge ${
                                  !isActive
                                    ? "badge-linesuccess"
                                    : "badge-danger"
                                }`}
                              >
                                <a>
                                  {!isActive
                                    ? t("general.actions.active")
                                    : t("general.actions.deactive")}
                                </a>
                              </span>
                            </div>
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                              <ImageWithBasePath
                                src={
                                  customer.gender === 1
                                    ? "assets/img/avatar/male-avatar.jpg"
                                    : "assets/img/avatar/female-avatar.jpg"
                                }
                                height={120}
                                width={120}
                              ></ImageWithBasePath>
                              <div className="mt-4 text-center">
                                <div className="text-black text-center fs-6">
                                  {t("customer.loyaltyID")}:
                                  <strong>{customer?.customer_code}</strong>
                                  <br />
                                  {t("tableColumnName.availablePoints")}:
                                  <strong>
                                    {" " + customer?.loyalty_points}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="p-3">
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.customerName")}
                                details={
                                  customer.first_name + " " + customer.last_name
                                }
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.customerPhone")}
                                details={customer.phone}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.customerEmail")}
                                details={customer.email}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.address")}
                                details={customer.address}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.zipCode")}
                                details={customer.zip}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.city")}
                                details={customer.city?.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.country")}
                                details={customer.country?.name}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Date Of Birth"}
                                details={moment(customer.dob).format(
                                  "DD MMM YYYY"
                                )}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Member Since"}
                                details={moment(customer.created_at).format(
                                  "DD MMM YYYY"
                                )}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Gender"}
                                details={customer.gender ? "Male" : "Female"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdBy")}
                                details={customer.createdBy}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h3 className="mb-2">{t("customer.saleHistory")}</h3>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={customer?.sales}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
