import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  File,
  User,
  UserCheck,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import outletService from "../../services/http/stores/outlet.service";
import { all_routes } from "../../Router/all_routes";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../utils/permissionFunction";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import useDashboardData from "./hooks/userDashboardData";
import SalesChart from "./features/dashboardChart";
import ProductsList from "./features/productsList";
import OutletList from "./features/outletList";
import ExpiredProductList from "./features/expiredProductsList";
import UnlimitBreadcrumb from "../../components/ui-kit/unlimit-breadcrumb";
const Dashboard = () => {
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("year"),
    end: moment(),
  });
  const { isVendorOrOutlet, roleType, userType } = useRoleAndPerm();
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletLoading, setOutletLoading] = useState(false);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const vendors = useSelector((state) => state.vendors.vendors);
  const countries = useSelector((state) => state.locations.countries);
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const { t, i18n } = useTranslation();
  const { dashboardData, loading, error } = useDashboardData(
    dateRange.start.format("YYYY-MM-DD"),
    dateRange.end.format("YYYY-MM-DD"),
    selectedVendor?.value,
    selectedOutletId?.value,
    selectedYear
  );

  const userCountry = countries.find(
    (country) => country.id === user.country_id
  );
  useEffect(() => {
    if (isVendorOrOutlet) {
      console.log(";;;;");
    } else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      // setSelectedVendor({
      //   ...selectedVendor,
      //   value: selectedVendor?.id,
      //   label: selectedVendor?.business_name,
      // });
    }
  }, []);
  useEffect(() => {
    setSelectedOutletId(null);
    if (selectedVendor?.value) {
      setOutletLoading(true);
      fetchOutlet(selectedVendor?.value);
    }else if(vendors?.data?.data[0].id){
      setOutletLoading(true);
      fetchOutlet(vendors?.data?.data[0].id);
    }
  }, [selectedVendor]);
  const handleDateRangeChange = (event, picker) => {
    setDateRange({
      start: picker.startDate,
      end: picker.endDate,
    });
  };
  async function fetchOutlet(vendorID) {
    try {
      const outlet = await outletService.outletList({
        vendor_id: vendorID,
      });
      setOutlet(outlet?.data?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setOutletLoading(false);
    }
  }
  const outletOptions = [
    { value: null, label: t('outlet.allOutlet') },
    ...(outlet ? outlet.map((outlet) => ({
      ...outlet,
      value: outlet.id,
      label: outlet.name,
    })) : []),
  ];
  
  const vendorOptions = [
    { value: null, label: t('vendor.allVendor') },
    ...(vendor ? vendor.map((vendor) => ({
      ...vendor,
      value: vendor.id,
      label: vendor.business_name,
    })) : []),
  ];
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("tableColumnName.dashboard"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header mb-0">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("tableColumnName.dashboard")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row mb-3">
          {!isVendorOrOutlet && (
              <div className="mb-0 col-lg-6 col-sm-6 col-12">
                <div className="unlimit-uikit-select">
                  <label className="mb-2">{t("vendor.vendor")}</label>
                  <Select
                    options={vendorOptions}
                    className="select custom-select"
                    placeholder={t('vendor.allVendor')}
                    value={selectedVendor}
                    formatOptionLabel={({ label, logo_url }) => (
                      <div className="d-flex">
                        {logo_url&& <img
                          src={logo_url}
                          alt=""
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 10,
                            objectFit: "contain",
                          }}
                        />}
                        <span style={{ marginLeft: 10 }}>{label}</span>
                      </div>
                    )}
                    onChange={(selectedOption) =>
                      setSelectedVendor(selectedOption)
                    }
                  />
                </div>
              </div>
          )}
              {roleType !== "outlet" && (
                <div className="col-lg-6 col-sm-6 col-12 ">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("outlet.outlet")}</label>
                    <Select
                      options={outletOptions}
                      className="select custom-select"
                      placeholder={t('outlet.allOutlet')}
                      value={selectedOutletId}
                      onChange={(selectedOption) =>
                        setSelectedOutletId(selectedOption)
                      }
                      formatOptionLabel={({ label, image }) => (
                        <div className="d-flex">
                          {image && <img
                            src={image}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />}
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      isLoading={outletLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          <div className="d-flex justify-content-end mb-2">
            <DateRangePicker
              initialSettings={{
                startDate: dateRange.start,
                endDate: dateRange.end,
              }}
              onApply={handleDateRangeChange}
            >
              <span className="card d-flex flex-row align-items-centee">
                <span className="fw-bold mx-2 p-2">
                  {new Date(dateRange.start).toLocaleDateString() +
                    " - " +
                    new Date(dateRange.end).toLocaleDateString()}
                </span>
                <button className="btn btn-primary">
                  <i className="feather-filter"></i>
                </button>
              </span>
            </DateRangePicker>
          </div>
          <div className="row">
            {/* <div className="col-md-3 col-4 d-flex">
              <div className="dash-widget dash3 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash4.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="me-1">{userCountry?.currency_symbol}</span>
                    <CountUp
                      start={0}
                      end={dashboardData?.widgetData?.earned_points?.count || 0}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>{t("tableColumnName.earnedPoints")}</h6>
                </div>
              </div>
            </div> */}
            {/* Bottom Cards */}
            <div className="custom-col col-4 col-md d-flex">
              <div className="dash-count">
                <div className="dash-counts">
                  <h4>{dashboardData?.widgetData?.customers?.count}</h4>
                  <h5>{t("customer.customers")}</h5>
                </div>
                <div className="dash-imgs">
                  <User />
                </div>
              </div>
            </div>
            <div className="custom-col col-4 col-md d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>{dashboardData?.widgetData?.sales?.count || 0}</h4>
                  <h5>{t("Sales.Sales")}</h5>
                </div>
                <div className="dash-imgs">
                  <UserCheck />
                </div>
              </div>
            </div>
            <div className="custom-col col-4 col-md d-flex">
              <div className="dash-count das2">
                <div className="dash-counts">
                  <h4>{dashboardData?.widgetData?.purchases?.count || 0}</h4>
                  <h5>{t("purchase.purchase")}</h5>
                </div>
                <div className="dash-imgs">
                  <ImageWithBasePath
                    src="assets/img/icons/file-text-icon-01.svg"
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
            <div className="custom-col col-4 col-md d-flex">
              <div className="dash-count das3">
                <div className="dash-counts">
                  <h4>
                    {dashboardData?.widgetData?.redeem_points?.count || 0}
                  </h4>
                  <h5>{t("tableColumnName.redeemPoints")}</h5>
                </div>
                <div className="dash-imgs">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/redeem.svg"
                      alt="img"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="custom-col col-4 col-md d-flex">
              <div className="dash-count das1">
                <div className="dash-counts">
                  <h4>
                    {dashboardData?.widgetData?.earned_points?.count || 0}
                  </h4>
                  <h5>{t("tableColumnName.earnedPoints")}</h5>
                </div>
                <div className="dash-imgs">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/Earn_A_points.svg"
                      alt="img"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-4 d-flex">
              <div className="dash-widget dash1 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash2.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="me-1">{userCountry?.currency_symbol}</span>
                    <CountUp
                      start={0}
                      end={dashboardData?.widgetData?.sales?.amount || 0}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>{t("dashboard.salesAmount")}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-4 d-flex">
              <div className="dash-widget w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/dash1.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="me-1">{userCountry?.currency_symbol}</span>
                    <CountUp
                      start={0}
                      end={dashboardData?.widgetData?.purchases?.amount || 0}
                      duration={3} // Duration in seconds
                    />
                  </h5>
                  <h6>{t("purchase.purchaseAmount")}</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-4 d-flex">
              <div className="dash-widget dash2 w-100">
                <div className="dash-widgetimg">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/revenue-rule-1.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="me-1">{userCountry?.currency_symbol}</span>{" "}
                    <CountUp
                      start={0}
                      end={dashboardData?.widgetData?.revenue?.amount || 0}
                      duration={3}
                    />
                  </h5>
                  <h6>{t("dashboard.totalRevenue")}</h6>
                </div>
              </div>
            </div>
          </div>
          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="card-title mb-0">
                    {t("purchase.purchase")} &amp; {t("tableColumnName.sales")}
                  </h5>
                  <div className="graph-sets">
                    <ul className="mb-0">
                      <li>
                        <span>{t("tableColumnName.sales")}</span>
                      </li>
                      <li>
                        <span>{t("purchase.purchase")}</span>
                      </li>
                    </ul>
                    <div className="dropdown dropdown-wraper">
                      <button
                        className="btn btn-light btn-sm dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedYear}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {[2024, 2023, 2022, 2021].map((year) => (
                          <li key={year}>
                            <p
                              to="#"
                              className="dropdown-item"
                              onClick={() => handleYearChange(year)}
                            >
                              {year}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <SalesChart dashboardData={dashboardData} t={t} />
              </div>
            </div>
            <ProductsList
              t={t}
              ProductsList={dashboardData.topProductsData}
              currency={userCountry?.currency_symbol}
            />
            {/* <ExpiredProductList t={t} /> */}
            <OutletList
              t={t}
              OutletsList={dashboardData.topOutletsData}
              currency={userCountry?.currency_symbol}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
