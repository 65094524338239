import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import httpService from "../../../services/http/support/ticket.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

const TicketList = () => {
  const { hasPermission, isVendorOrOutlet } = useRoleAndPerm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [selectedPriorty, setSelectedPriorty] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getTicketList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .ticketList(data)
      .then((response) => {
        if (response.status == 200) {
          setTickets(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
      priority: selectedPriorty?.value,
      status: selectedStatus?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getTicketList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    if (isVendorOrOutlet) {
      getTicketList();
    } else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      setSelectedVendor({
        ...selectedVendor,
        value: selectedVendor?.id,
        label: selectedVendor?.business_name,
      });
    }
  }, []);

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: t("tableColumnName.ticketNumber"),
      dataIndex: "ticket_number",
      key: "ticket_number",
    },
    {
      title: t("tableColumnName.requesterName"),
      dataIndex: "customer.name",
      render: (text, record) => {
        return record.customer?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.subject"),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: t("tableColumnName.assignee"),
      dataIndex: "user.name",
      render: (text, record) => {
        return record.user?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.priority"),
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("inputField.label.status"),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color, backgroundColor;
        switch (status) {
          case "open":
            color = "#82B632";
            backgroundColor = "rgba(130, 182, 50, 0.10)";
            break;
          case "closed":
            color = "#EA5455";
            backgroundColor = "rgba(234, 84, 85, 0.10)";
            break;
          case "Pending":
            color = "#FF9900";
            backgroundColor = "rgba(255, 153, 0, 0.10)";
            break;
          default:
            color = "blue";
            backgroundColor = "rgba(0, 0, 255, 0.1)";
        }
        return (
          <span
            style={{
              color: color,
              border: `1px solid ${color}`,
              backgroundColor: backgroundColor,
              padding: "4px 8px",
              borderRadius: "4px",
              display: "inline-block",
              width: "100%",
            }}
            className="d-flex justify-content-center"
          >
            {status}
          </span>
        );
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "action",
      hidden: !hasPermission(["ticket-view"]),
      render: (text, record) => (
        <div className="edit-delete-action">
          {hasPermission(["ticket-view"]) && (
            <a
              className="p-2"
              onClick={() => navigate(`/ticket/${record.id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
          )}
        </div>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Ticket List", active: true },
  ];
  const statusOptions = [
    { value: null, label: "Select All" },
    { value: "open", label: "Open" },
    { value: "closed", label: "Closed" },
  ];

  const priortyOptions = [
    { value: null, label: "Select All" },
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("ticket.ticketList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet && <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-5 col-sm-5 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                              objectFit: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>}
              <div className="table-top row">
                <div className="col-lg-3 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterName")}
                    className="form-control form-control-sm formsearch-custom ps-2 "
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 ">
                  <div className="unlimit-uikit-select">
                    <Select
                      options={priortyOptions}
                      className="select"
                      placeholder={t("inputField.placeholder.choosePriority")}
                      value={selectedPriorty}
                      onChange={(selectedOption) =>
                        setSelectedPriorty(selectedOption)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <Select
                      options={statusOptions}
                      className="select "
                      placeholder={t("inputField.placeholder.chooseStatus")}
                      value={selectedStatus}
                      onChange={(selectedOption) =>
                        setSelectedStatus(selectedOption)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-12  ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto"
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={tickets}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default TicketList;
