import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/peoples/vendor.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useDispatch } from "react-redux";
import { fetchVendors } from "../../../core/redux/reducers/vendors.slice";
const VendorList = () => {
  const { hasPermission } = useRoleAndPerm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [cvrNoFilter, setCvrNoFilter] = useState("");
  const dispatch = useDispatch();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getVendorsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .vendorsList(data)
      .then((response) => {
        if (response.status == 200) {
          setVendors(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      email: emailFilter,
      cvr_no: cvrNoFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getVendorsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getVendorsList();
  }, []);

  const { vendorCreate } = all_routes;

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteVendor(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("vendor.VendorDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getVendorsList();
          if (hasPermission(["vendor-view"])) dispatch(fetchVendors(true));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.vendorId"),
      dataIndex: "vendor_code",
      sorter: true,
    },
    {
      title: t("tableColumnName.businessName"),
      dataIndex: "vendor",
      render: (text, record) => (
        <span className="productimgname">
          <a className="product-img stock-img">
            <ImageWithBasePath alt={text} type="link" src={record.logo} style={{ width: 30, height: 30, marginRight: 10 }}/>
            <span className="ms-2">{record.business_name}</span>
          </a>
        </span>
      ),
    },
    {
      title: t("tableColumnName.cvrNo"),
      dataIndex: "cvr_no",
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
      sorter: true,
    },
    {
      title: t("tableColumnName.outlets"),
      key: "outlet",
      render: (text, record) => (
        <div className="text-center">{record.outlet?.length ?? 0}</div>
      ),
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) =>
        record.is_active == 1 ? (
          <span className="badge badge-linesuccess">
            <a>{t("general.actions.active")}</a>
          </span>
        ) : (
          <span className="badge badge-linedanger">
            <a>{t("general.actions.deactive")}</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["vendor-view", "vendor-edit", "vendor-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["vendor-view"]) && (
              <Link
                to={`/vendor/${record.id}/view`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
            {hasPermission(["vendor-edit"]) && (
              <a
                className="me-2 p-2"
                onClick={() => navigate(`/vendor/${record.id}/edit`)}
              >
                <i className="feather-edit-2"></i>
              </a>
            )}
            {hasPermission(["vendor-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("vendor.vendor"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("vendor.vendorList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["vendor-add"]) && (
              <div className="page-btn">
                <Link to={vendorCreate} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </Link>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top row">
                    <div className="col-lg-5 col-sm-5 col-12">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={emailFilter}
                        onChange={(e) => setEmailFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-5 col-sm-5 col-12">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterCvrNo")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={cvrNoFilter}
                        onChange={(e) => setCvrNoFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks mb-0">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={vendors}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default VendorList;
