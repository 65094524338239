import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts"; // assuming you're using react-apexcharts

const SalesChart = ({ dashboardData, t }) => {
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: t("tableColumnName.sales"),
        data: [], // initially empty
      },
      {
        name: t("purchase.purchase"),
        data: [], // initially empty3
      },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    // yaxis: {
    //   min: -200,
    //   max: 300,
    //   tickAmount: 5,
    // },
    xaxis: {
      categories: [], // initially empty
    },
    legend: { show: false },
    fill: {
      opacity: 1,
    },
  });

  useEffect(() => {
    if (dashboardData?.salePurchaseGraphData) {
      const salePurchaseGraphData = dashboardData.salePurchaseGraphData;

      const salesData = Object.values(salePurchaseGraphData).map(
        (item) => item?.sales || 0
      );
      const purchasesData = Object.values(salePurchaseGraphData).map(
        (item) => -(item?.purchases || 0) // Negative values for purchases
      );
      const months = Object.keys(salePurchaseGraphData).map((month) =>
        t(`months.${month.substring(0, 3)}`)
      );

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: t("tableColumnName.sales"),
            data: salesData,
          },
          {
            name: t("purchase.purchase"),
            data: purchasesData,
          },
        ],
        xaxis: {
          ...prevOptions.xaxis,
          categories: months,
        },
      }));
    }
  }, [dashboardData, t]); 

  return (
    <div className="card-body">
      <div id="sales_charts" />
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="bar"
        height={320}
      />
    </div>
  );
};

export default SalesChart;
