import React, { useEffect, useState } from "react";
import supplierHttpService from "../../../services/http/peoples/supplier.service";
import categoryHttpService from "../../../services/http/products/category.service";
import httpService from "../../../services/http/products/product.service";
import usePagination from "../../../custom-hooks/usePagination";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import { useForm } from "react-hook-form";
import UnlimitButton from "../../../components/ui-kit/unlimit-button";
import { useTranslation } from "react-i18next";

const ProductFromCatalogSearch = ({
  setIsListVisible,
  setCatalogList,
  setErrMessage,
  vendorId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [supplierLoading, setSupplierLoading] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  console.log(vendorId,"vendorIDvendorIDvendorIDvendorIDvendorIDvendorID")
  const { errors } = formState;
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const getProductList = async (filters) => {
    const data = {
      ...filters,
      page: paginationParams.pagination.current,
    };
    await httpService
      .productList(data)
      .then((response) => {
        if (response.status == 200) {
          setCatalogList(response.data.data?.data);
          setErrMessage("No results found");
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination
          },
        });
      })
      .finally(() => {
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getProductList(pagination);
  };
  async function fetchSupplier() {
    const supplier = await supplierHttpService.supplierList({vendor_id:vendorId}).catch((error)=>{
      console.log(error)
    }).finally(()=>{
      setSupplierLoading(false)
    });
    const activeSuppliers = supplier?.data?.data?.data?.filter(supplier => supplier.is_active == '1');
    setSupplier(activeSuppliers);
  }

  async function fetchCategory() {
    const category = await categoryHttpService.categoriesList().catch((error)=>{
      console.log(error)
    }).finally(()=>{
      setCategoryLoading(false)
    });
    setCategory(category?.data?.data?.data);
  }
  useEffect(() => {
    fetchCategory();
    setCategoryLoading(true)
  }, []);
  useEffect(() => {
  setSupplierLoading(true)
  fetchSupplier();
}, [vendorId]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const filters = {
        name: data.name,
        ean: data.ean,
        supplier_id: data.supplier_id?.id,
      };
      await getProductList(filters);
    } finally {
      setIsLoading(false);
      setIsListVisible(true);
    }
  };

  return (
    <div>
      {/* /Search */}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-md-4">
                <UnlimitInput
                  label={t("inputField.placeholder.enterEAN")}
                  id="ean"
                  type="text"
                  placeholder={t("inputField.placeholder.enterEAN")}
                  register={register}
                  rules={{
                    pattern: {
                      value: /^\d{4,14}$/,
                      message: t("inputField.validation.eanPattern"),
                    },
                  }}
                  error={errors.ean}
                  errorMessage={errors.ean?.message}
                />
              </div>
              <div className="col-md-4">
                <UnlimitSelect
                  label={t('supplier.supplier')}
                  id="supplier_id"
                  errors={errors}
                  control={control}
                  isLoading={supplierLoading}
                  register={register}
                  options={supplier}
                  placeholder={t('inputField.label.selectSupplier')}
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div className="col-md-4">
                <UnlimitInput
                  label={t('tableColumnName.productName')}
                  id="name"
                  type="text"
                  placeholder={t('inputField.placeholder.enterProductName')}
                  register={register}
                  error={errors.name}
                  errorMessage={errors.name?.message}
                />
              </div>
            </div>
            <UnlimitButton text={t("general.button.search")} isLoading={isLoading} />
            {/* /Filter */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductFromCatalogSearch;
