import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

const ProductList = ({ t, ProductsList }) => {
  return (
    <div className="col-xl-6 col-sm-12 col-12 d-flex">
      <div className="card flex-fill default-cover mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title mb-0">{t("dashboard.topSellingProducts")}</h4>
          <div className="view-all-link">
            <Link to="#" className="view-all d-flex align-items-center">
              <i className="bx bx-dots-vertical-rounded fs-4" />
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive dataview">
            <table className="table dashboard-recent-products">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("product.products")}</th>
                  <th>{t("Sales.SalesCount")}</th>
                </tr>
              </thead>
              <tbody>
                {ProductsList && ProductsList.map((product, index) => (
                  <tr key={product.product_id}>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                      <Link to={`/product/${product.product_id}/details`} className="product-img">
                        <ImageWithBasePath
                          src={product.image}
                          alt={product.product_name}
                          type='link'
                        />
                      </Link>
                      <Link to={`/product/${product.product_id}/details`}>
                        {product.product_name}
                      </Link>
                    </td>
                    <td>{product.total_quantity_sold}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
