import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/peoples/supplier.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import notificationService from "../../../services/notification.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import purchasehttpService from "../../../services/http/purchases/purchase.service";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import { store } from "../../../core/redux/store";
import moment from "moment";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import AddDocuments from "../../../components/add-documents";
import DocumentModal from "../../../components/view-document";
import ConfirmationAlertDeleteAttachments from "../../../components/confirm-alert-attachment-delete";
import { Upload } from "feather-icons-react/build/IconComponents";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

export default function SupplierDetails() {
  const { hasPermission } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const [supplier, setSupplier] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getSupplier = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getSupplier(id);
      if (response && response.status === 200) {
        setSupplier(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSupplier();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("supplier.suppliers"), link: "/supplier-list" },
    { label: t("supplier.supplierDetails"), active: true },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  const updateSupplier = async () => {
    const requestData = {
      is_active: isActive ? 1 : 0,
      id: id
    };
    await httpService
      .updateSupplier(requestData,true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(
            t("supplier.supplierUpdateSuccess")
          );
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onConfirmDelete = async (id) => {
    await purchasehttpService
      .deletePurchase(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("purchase.purchaseDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getSupplier();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const columns = [
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "order_no",
    },
    {
      title: t("tableColumnName.purchaseDate"),
      dataIndex: "purchase_date",
      render: (text) => {
        return moment(text).format("DD MMM YYYY");
      },
      sorter: true,
    },
    {
      title: t("tableColumnName.grandTotal"),
      dataIndex: "grand_total",
      render: (text, record) => {
        return record?.grand_total ?? "N/A";
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
        {
          title: t("tableColumnName.actions"),
          key: "actions",
          hidden:!hasPermission(["purchase-view", "purchase-delete"]),
          render: (text, record) => (
            <td className="action-table-data">
              <div className="edit-delete-action">
                {hasPermission(["purchase-view"]) && (
                  <Link
                    to={`/purchase/${record.id}/details`}
                    className="me-2 edit-icon p-2"
                  >
                    <i className="feather-eye"></i>
                  </Link>
                )}
                {hasPermission(["purchase-delete"]) && (
                  <ConfirmationAlert
                    onConfirm={() => onConfirmDelete(record.id)}
                  />
                )}
              </div>
            </td>
          ),
        },
  ];
  const documentsColumns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.documentName"),
      dataIndex: "name",
    },
    {
      title: t("tableColumnName.fileType"),
      dataIndex: "type",
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <a className="p-2" onClick={() => handleViewDocument(record)}>
              <i className="feather-eye"></i>
            </a>
            <ConfirmationAlertDeleteAttachments
              id={record.id}
              get={getSupplier}
            />
          </div>
        </td>
      ),
    },
  ];
  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  const onHideModal = async () => {
    getSupplier();
    console.log("hide modal");
  };
  const createDocument = async (formData) => {
    const response = await httpService.createSupplierAttachments(formData);
    if (response && response.status === 200) {
      notificationService.successMessage(t("document.documentUploadSuccess"));
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("supplier.supplierDetails")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <div>
            <div className="pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                supplier && (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="border card p-3 mb-3">
                          <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                            <span
                              className={`badge align-self-end ${
                                !isActive ? "badge-linesuccess" : "badge-danger"
                              }`}
                            >
                              <a>
                                {!isActive
                                  ? t("general.actions.activate")
                                  : t("general.actions.deactive")}
                              </a>
                            </span>
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                              <ImageWithBasePath
                                src={supplier?.image}
                                height={120}
                                width={120}
                                type="link"
                              ></ImageWithBasePath>
                              <div className="mt-4 text-center">
                                <div className="text-black text-center fs-6">
                                  <strong>{supplier?.name}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex w-100 mt-4 gap-3">
                            {hasPermission(["supplier-edit"]) && (
                              <UnlimitHyperlink
                                to={`/supplier/${id}/edit`}
                                className="btn btn-primary"
                                type="router-link"
                                style={{ flex: 1 }}
                              >
                                {t("general.actions.edit")}
                              </UnlimitHyperlink>
                            )}
                            {hasPermission(["supplier-delete"]) && (
                              <ConfirmationAlertDeactivate
                                onConfirm={() => updateSupplier()}
                                isActive={isActive}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="p-3 border card">
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.supplierName")}
                                details={supplier.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.phone")}
                                details={supplier.phone}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.email")}
                                details={supplier.email}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.address")}
                                details={supplier.address}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.zipCode")}
                                details={supplier.zip_code}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.city")}
                                details={supplier.city?.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.country")}
                                details={supplier.country?.name}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdAt")}
                                details={moment(supplier.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdBy")}
                                details={supplier.created_by?.name}
                              />
                            </div>
                            <div className="col-sm"></div>
                          </div>
                          <div className="row">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("inputField.label.description")}
                                details={supplier.description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <h4 className="fw-bold mb-3">{t("purchase.purchaseList")}</h4>
          <div className="card">
            <div className="table-responsive">
              <UnlimitTable
                pagination={paginationParams.pagination}
                columns={columns}
                dataSource={supplier?.purchases}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bold">{t("document.documents")}</h4>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add-department"
              className="btn btn-added"
            >
              <Upload className="me-2 iconsize" />
              {t("general.actions.upload")}
            </Link>
          </div>
          <div className="card">
            {pageLoading ? (
              <div className="row d-flex justify-content-center">
                <UnlimitLoading width={50} height={50} />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <UnlimitTable
                    pagination={false}
                    columns={documentsColumns}
                    dataSource={supplier?.attachments}
                    rowKey={(record) => record.id}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            )}
          </div>
          <AddDocuments
            onHide={onHideModal}
            recordId={supplier?.id}
            createDocument={createDocument}
          />
          <DocumentModal
            show={showModal}
            onHide={() => setShowModal(false)}
            document={selectedDocument}
          />
        </div>
      </div>
    </>
  );
}
